const Competences = (props) => {

    const name = props.name;
    const width = props.width;
    const icons = props.icons;

    function formats() {

        var format;

        if (icons === 'database') {
            format = 'solid'
        } else {
            format = 'brands'
        }

        return format;
    };

    return (
        <>
            <div className="col-12 col-md-6">
                <div animation="animate__zoomInUp" className="bg-dark animate__animated rounded bg-card">
                    <div className="p-1 d-flex flex-column justify-content">
                        <div className="d-flex flex-column m-2 h-100">
                            <div className="d-flex p-2">
                                <i className={"fs-2 mt-auto mb-auto fa-" + formats() + " fa-" + icons + " dark-color m-3"}></i>
                                <p className="h4 m-0 text-uppercase text-white">{name}</p>
                            </div>
                            <div className="content">
                                <div className="progress">
                                    <div className="bg-color progress-bar" role="progressbar" aria-label="Example with label" style={{ width }} aria-valuenow={width} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Competences;