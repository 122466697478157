import React, { useEffect, useState } from 'react';
import { useForm } from '@formspree/react';

function Contact(props) {

    const sendNotification = props.sendNotification

    const [state, handleSubmit] = useForm("mnqyjwpw");

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (state.submitting) {
            setSubmitting(true)
            return;
        }

        if (!state.submitting && submitting) {
            setSubmitting(false)
            if (!state.succeeded) {
                if (state.errors.length === 0)
                    return;

                sendNotification("Baptiste BOUTEILLE", "Votre message n'a pas était envoyer tous les champs sont requis.", 10000, "text-danger")
            } else {
                sendNotification("Baptiste BOUTEILLE", "Votre message de contact à été reçu avec succès. j'y répondrais dans les meilleurs délais.", 10000, "text-success")
            }
        }
    }, [state])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="mb-3 text-white">
                    <label className="form-label"> Votre E-mail :</label>
                    <input className="form-control form-control2" type="email" name="Email" placeholder="name@example.com" />
                </div>
                <div className="mb-3 text-white">
                    <label className="form-label"> Votre nom :</label>
                    <input type="text" className="form-control form-control2" name="Name" placeholder="Your name" />
                </div>
                <div className="mb-3 text-white">
                    <label className="form-label"> Votre message :</label>
                    <textarea className="form-control form-control2" type="message" name="Message" placeholder="Entrez votre message" rows="6"></textarea>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="button" type="submit" disabled={state.submitting}>
                        <div className="svg-wrapper-1">
                            <div className="svg-wrapper">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                </svg>
                            </div>
                        </div>
                        <span>Send</span>
                    </button>
                </div>
            </form>
        </>
    );
}

export default Contact;