import { useState } from "react";

const Abouttext = () => {

    const [hidden, setHidden] = useState(true)

    return (
        <>
            <div className="text-start text-white">
                <p>
                    Bonjour, je m'appelle Baptiste Bouteille jeune développeur informatique passionné, âgé de 19 ans. J'ai commencé mon parcours dans le développement
                    informatique il y a 5 ans lorsque j'ai découvert ma passion pour la programmation. J'ai commencé par apprendre les bases du développement Web en codant en HTML et CSS.
                </p>
                <p>
                    Au fil des ans, j'ai continué à élargir mes compétences en apprenant d'autres langages de programmation tels que le JavaScript, le PHP, ReactJS, Python et C#.
                    Mon baccalauréat en système numérique m'a également permis d'acquérir une connaissance approfondie des réseaux informatiques, ce qui m'a donné une vue d'ensemble du
                    monde de l'informatique.
                </p>
                <p>
                    Je suis actuellement en première année de BTS SIO (Slam), où je poursuis mes études en développement informatique. Mon objectif est de devenir un développeur expérimenté
                    et de continuer à développer mes compétences dans ce domaine passionnant.
                </p>
                <button id="button" type="button" className={!hidden && "hidden"} onClick={() => setHidden(false)} >
                    <span>
                        Voir plus
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style={{marginLeft: "0.5em"}} fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                    </span>
                </button>
                <p className={hidden && "hidden"}>
                    En tant que développeur débutant, je suis toujours à la recherche de nouveaux défis et de projets pour améliorer mes compétences. 
                    J'ai déjà travaillé sur de nombreux projets personnels et professionnelle, ce qui m'a permis de développer mon portfolio. 
                    Je suis convaincu que mes compétences en développement informatique, combinées à ma passion pour le métier, me permettent de fournir des solutions à mes project professionnelle.
                </p>
                <p className={hidden && "hidden"}>
                    Si vous cherchez un développeur informatique compétent et passionné pour votre projet, n'hésitez pas à me <a href="#contact" className="bg-orange-text">contacter</a>. 
                    Je suis prêt à mettre mes compétences à votre disposition pour vous aider à atteindre vos objectifs.
                </p>
            </div>
        </>
    );
};

export default Abouttext;