/*
function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
            if(reveals[i].classList.contains("container")) {
                reveals[i].classList.add("animate__fadeInLeft");
            } else if (reveals[i].classList.contains("top-container")) {
                reveals[i].classList.add("animate__bounceInDown");
            } else if (reveals[i].classList.contains("a-start")) {
                reveals[i].classList.add("animate__backInLeft");
            } else if (reveals[i].classList.contains("a-end")) {
                reveals[i].classList.add("animate__backInRight");
            } else if (reveals[i].classList.contains("flip")) {
                reveals[i].classList.add("animate__flipInX");
            } else if (reveals[i].classList.contains("a-up")) {
                reveals[i].classList.add("animate__bounceInUp");
            }
        }
    }
}

reveal();

*/

/**
 @author DoryanBESSIERE
 */
const reveal = () => {
    var reveals = document.querySelectorAll(".animate__animated");
    
    for (var i = 0; i < reveals.length; i++) {
        const component = reveals[i];
        var windowHeight = window.innerHeight;
        var elementTop = component.getBoundingClientRect().top;
        var elementVisible = 150;
        
        if (elementTop < windowHeight - elementVisible) {
            if (!component.classList.contains("ignore")) {
                const animation = component.getAttribute("animation");
                component.classList.add("active");
                component.classList.add(animation);
            }
        }
    }
}

reveal();
window.addEventListener("scroll", reveal);