import React, { useEffect, useRef, useState } from 'react';
import * as bootstrap from 'bootstrap';

const Notification = (props) => {

    const title = props.title;
    const badge = props.badge;
    const timeout = props.timeout;
    const style = props.style;


    const [hidden, setHidden] = useState(false);
    const reference = useRef();

    const show = () => {
        const toastObject = new bootstrap.Toast(reference.current)
        toastObject.show();
    }

    useEffect(() => {
        show();

        setTimeout(() => {
            setHidden(true)
        }, timeout + 1000);
    }, [])

    if (hidden) return <></>;

    return (
        <div ref={reference} className="toast opa bg-dark" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay={timeout} >
            <div className="toast-header opa bg-dark">
                <strong className="me-auto text-light">{title}</strong>
                <span className="badge bg-prymari">{badge}</span>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div className={"toast-body "+ style +""}>
                {props.children}
            </div>
        </div>
    );
};

export default Notification;