import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import '../style.css';
import '../reveal';
import React from "react";
import Competences from '../competences';
import Article from '../article';
import Experiences from '../experiences';
import Abouttext from '../about-metext';
import About from '../about-me';
import Contact from '../contact';

import backgroud from '../img/backgroud.jpg';
import logo from '../img/logo.gif';
import MbFc from '../img/bandeau.png';
import v2 from '../img/v2.png';
import v1 from '../img/v1.png';
import v3 from '../img/v3.png';
import perso from '../img/perso2.png';
import sigma from '../img/logo-sigma.png';
import ataraxie from '../img/ataraxie.png';
import Raynier from '../img/RAYNIER-LOGO.jpg';
import wd from '../img/baptiste-wd.png';

const MyPage = (props) => {

  const sendNotification = props.sendNotification;

  return (
    <>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark opa05">
        <div className="container-fluid">
          <img className="navbar-brand" alt="logo" src={logo} />
          <div className="d-flex muted-color2" style={{ height: "2.5em" }}>
            <div className="vr"></div>
          </div>
          <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link" href="#home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#projects">Projects</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#competences">Compétences</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#experiences">Expériences</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div id="home" className="position-relative">
        <div className="d-flex background">
          <img src={backgroud} alt="logo de fond stylisé" className="background" />
        </div>
        <div className="position-absolute top-50 start-50 translate-middle text-white text-center">
          <div className="row justify-content-center">
            <div className="col-12">
              <h1 className="big-title fw-bolder animate__animated ignore animate__rubberBand text-uppercase">Baptiste BOUTEILLE</h1>
            </div>
            <div className="col-auto">
              <h3 className="fw-bold mb-3 animate__animated ignore animate__lightSpeedInLeft"><span className="text-uppercase">Développeur Full Stack</span></h3>
            </div>
          </div>
          <div className="row justify-content-center animate__animated ignore animate__bounceInUp">
            <div className="col-auto d-flex">
              <i className="fs-1 mt-auto mb-auto fa-brands fa-html5"></i>
            </div>
            <div className="col-auto d-flex">
              <i className="fs-1 mt-auto mb-auto fa-brands fa-css3-alt"></i>
            </div>
            <div className="col-auto d-flex">
              <i className="fs-1 mt-auto mb-auto fa-brands fa-php"></i>
            </div>
            <div className="col-auto d-flex">
              <i className="fs-1 mt-auto mb-auto fa-brands fa-bootstrap"></i>
            </div>
            <div className="col-auto d-flex">
              <i className="fs-1 mt-auto mb-auto fa-solid fa-database"></i>
            </div>
            <div className="col-auto d-flex">
              <i className="fs-1 mt-auto mb-auto fa-brands fa-wordpress"></i>
            </div>
            <div className="col-auto d-flex">
              <i className="fs-1 mt-auto mb-auto fa-brands fa-square-js"></i>
            </div>
            <div className="col-auto d-flex">
              <i className="fs-1 mt-auto mb-auto fa-brands fa-react"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="py-2" id="about-me"></div>

      <div animation="animate__flipInX" className="container container-color animate__animated">
        <h1 className="fw-bold text-uppercase">Bienvenue sur mon Portfolio !</h1>
        <h5 className="muted-color">Toute les informations sur ce site me concerne et sont mis a jour régulièrement</h5>
        <hr className="hr-color" />
      </div>

      <div id="about-me" className="container">
        <div animation="animate__bounceInDown" className="text-center d-flex flex-column animate__animated">
          <h1 className="roboto fw-bolder text-uppercase mb-2 lh-1">À propos</h1>
          <span className="text-muted text-uppercase">Apprenez-en un peu plus sur moi</span>
          <div className="mt-3 mb-5 seperator rounded ms-auto me-auto"></div>
          <div className="row g-4">
            <div className="col-md-4">
              <div animation="animate__backInLeft" className="animate__animated justify-content-center">
                <About />
              </div>
            </div>
            <div className="col-md-8">
              <div animation="animate__backInRight" className="animate__animated">
                <Abouttext />
              </div>
            </div>
          </div>
        </div>
      </div>

      <svg id="projects" xmlns="http://www.w3.org/2000/svg" viewBox="0 60 1440 200"><path fill="#2E4053" fillOpacity="1" d="M0,224L21.8,218.7C43.6,213,87,203,131,176C174.5,149,218,107,262,106.7C305.5,107,349,149,393,181.3C436.4,213,480,235,524,218.7C567.3,203,611,149,655,112C698.2,75,742,53,785,85.3C829.1,117,873,203,916,202.7C960,203,1004,117,1047,117.3C1090.9,117,1135,203,1178,208C1221.8,213,1265,139,1309,112C1352.7,85,1396,107,1418,117.3L1440,128L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>

      <div id="projects" className="bg-gray">
        <div className="container">
          <div animation="animate__bounceInDown" className="text-center d-flex flex-column animate__animated">
            <h1 className="roboto fw-bolder text-uppercase mb-2 lh-1">Mes projets</h1>
            <span className="text-muted text-uppercase">Retrouvez tout les sites que j'ai réalisé</span>
            <div className="mt-3 mb-5 seperator rounded ms-auto me-auto"></div>
          </div>

          <div animation="animate__fadeInLeft" className="row g-4 animate__animated">
            <div className="col-md-4">
              <Article model={{objectFit: 'cover'}} img={v3} title="Baptistebouteille.fr" type="Application web" description="Pendant l'apprentisage du React JS j'ai décidé de mettre en oeuvre le refonte de mon portfolio avec des optimisations et des contenues graphiques." icons={["html5", "css3", "react", "js"]} lient="http://baptistebouteille.fr/"></Article>
            </div>
            <div className="col-md-4">
              <Article model={{objectFit: 'cover'}} img={v2} title="Baptistebouteille.fr V2" type="Site web" description="Dans le cadre de mes besoins et de mes exigences je me suis lancer au defit de refaire tout mon portfolio pour qui soit présentable et à jour." icons={["html5", "css3", "js"]} lient="http://baptistebouteille.fr/portfolio-v2/"></Article>
            </div>
            <div className="col-md-4">
              <Article model={{objectFit: 'cover'}} img={v1} title="Baptistebouteille.fr V1" type="Site web" description="Dans le cadre d'un satge pendant mes années d'études du BAC mon mître de stage m'avais donner à faire un portfolio que j'ai donc adapter selon mes besoint." icons={["html5", "css3"]} lient="http://baptistebouteille.fr/portfolio-v1/"></Article>
            </div>
            <div className="col-md-4">
              <Article model={{objectFit: 'cover'}} img={wd} title="Baptiste W-D" type="Site web" description="Site professionnel pour prendre contact ou demander des devis pour des sites web" icons={["html5", "css3", "js", "database", "php"]} lient="http://baptistebouteille.fr/baptiste-wd"></Article>
            </div>
            <div className="col-md-4">
              <Article model={{objectFit: 'cover'}} img={MbFc} title="MB-FC" type="Site web" description="Ce site et l'un de mes premières expériences en développement web, j'ai réalisé un site internet pour une micro-entreprise de formation d'informatique." icons={["html5", "css3", "database", "php"]} lient="https://mb-fc.fr"></Article>
            </div>
            <div className="col-md-4">
              <Article model={{objectFit: 'contain'}} img={Raynier} title="raynier plomberie" type="Site web" description="Site web crée pour l'entreprise Raynier base à Carcassonne (Site en construction)" icons={["html5", "css3", "js", "php", "database"]} lient="https://raynier-plomberie.fr"></Article>
            </div>
            <div className="col-md-4">
              <Article model={{objectFit: 'none'}} img="https://www.salsigneminesetmemoire.fr/wp-content/uploads/2022/06/logo-salsigne-villaniere.jpg" title="Mine et mémoire" type="Site web" description="Dans le cadre de mes déveleppement j'ai accepté de tenir à jour et refonder le site web d'une association qui est toujours en activité." icons={["wordpress"]} lient="https://www.salsigneminesetmemoire.fr"></Article>
            </div>
          </div>
        </div>
      </div>

      <svg id="competences" xmlns="http://www.w3.org/2000/svg" viewBox="0 60 1440 200"><path fill="#2E4053" fillOpacity="1" d="M0,224L24,234.7C48,245,96,267,144,240C192,213,240,139,288,133.3C336,128,384,192,432,186.7C480,181,528,107,576,112C624,117,672,203,720,218.7C768,235,816,181,864,154.7C912,128,960,128,1008,149.3C1056,171,1104,213,1152,213.3C1200,213,1248,171,1296,154.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>

      <div id="competence" className="container">
        <div animation="animate__bounceInDown" className="text-center d-flex flex-column animate__animated">
          <h1 className="text-center fw-bolder text-uppercase mb-2 lh-1">Compétences</h1>
          <span className="text-muted text-uppercase">Les différentes compétences que j'ai acquises (avis personnel)</span>
        </div>
        <div className="mt-3 mb-5 seperator rounded ms-auto me-auto"></div>
        <div className="card bg-dark">
          <div className="row g-4 mb-5">
            <Competences name="html" icons="html5" width="75%"></Competences>
            <Competences name="css" icons="css3-alt" width="65%"></Competences>
            <Competences name="bootstrap" icons="bootstrap" width="70%"></Competences>
            <Competences name="php" icons="php" width="60%"></Competences>
            <Competences name="react" icons="react" width="7%"></Competences>
            <Competences name="database" forma="solid" icons="database" width="75%"></Competences>
            <Competences name="wordpress" icons="wordpress" width="55%"></Competences>
            <Competences name="Java Script" icons="square-js" width="45%"></Competences>
            <Competences name="Python" icons="python" width="20%"></Competences>
            <Competences name="linux" icons="linux" width="15%"></Competences>
          </div>
        </div>
      </div>

      <svg id="experiences" xmlns="http://www.w3.org/2000/svg" viewBox="0 60 1440 200"><path fill="#2E4053" fillOpacity="1" d="M0,224L21.8,218.7C43.6,213,87,203,131,176C174.5,149,218,107,262,106.7C305.5,107,349,149,393,181.3C436.4,213,480,235,524,218.7C567.3,203,611,149,655,112C698.2,75,742,53,785,85.3C829.1,117,873,203,916,202.7C960,203,1004,117,1047,117.3C1090.9,117,1135,203,1178,208C1221.8,213,1265,139,1309,112C1352.7,85,1396,107,1418,117.3L1440,128L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>

      <div id="experience" className="bg-gray">
        <div className="container">
          <div className="d-flex flex-column text-center">
            <div animation="animate__fadeInUp" className="animate__animated active animate__fadeInUp">
              <h1 className="text-center fw-bolder text-uppercase mb-2 lh-1">Mes expériences</h1>
              <span className="text-muted text-uppercase">Mon parcours professionnel</span>
            </div>
            <div className="mt-3 mb-5 seperator rounded ms-auto me-auto"></div>
          </div>
        </div>

        <div className="container pb-5">
          <div className="row justify-content-center container text-white">
            <Experiences img={sigma} title="Stagiaire" times="Durée de 3 mois" liste={["Apprentisage du C#", "Approfondissement des languages html, css et bootstrap", "Montage de serveurs, ordinateur portables et fixes"]} style={["w-100"]}></Experiences>
            <Experiences img={ataraxie} title="Stagiaire" times="Durée de 1 mois" liste={["Apprentisage des languages html et css", "Installation de téléphonie IP", "Gestion d'un datacenter"]} style={["w-50 bg-white rounded-circle"]}></Experiences>
          </div>
        </div>
      </div>

      <svg id="contact" xmlns="http://www.w3.org/2000/svg" viewBox="0 60 1440 200"><path fill="#2E4053" fillOpacity="1" d="M0,224L24,234.7C48,245,96,267,144,240C192,213,240,139,288,133.3C336,128,384,192,432,186.7C480,181,528,107,576,112C624,117,672,203,720,218.7C768,235,816,181,864,154.7C912,128,960,128,1008,149.3C1056,171,1104,213,1152,213.3C1200,213,1248,171,1296,154.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>

      <div className=" pb-4">
        <div className="container">
          <div animation="animate__bounceInDown" className="text-center d-flex flex-column animate__animated">
            <h1 className="roboto fw-bolder text-uppercase big-title mb-2 lh-1">Contact</h1>
            <p className="text-muted fs-2 lh-1 mb-0">Contactez moi (Tous les champs sont requis)</p>
            <div className="mt-3 mb-5 seperator rounded ms-auto me-auto"></div>
              <Contact sendNotification={sendNotification}/>
          </div>
        </div>
      </div>

      <div className="container text-white">
        <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-3 py-5">
          <div className="col mb-3 d-flex flex-column mb-5">
            <img src={perso} className="m-auto logo-navbar" alt="perso" />
            <p className="mb-0 lh-1 pt-2 m-auto">Développé par <strong className="bg-orange-text">Baptiste BOUTEILLE</strong></p>
            <p className="m-auto text-muted">© 2023 | Tout droit réservé au développeur</p>
          </div>

          <div className="col d-flex flex-column justify-content-center mb-5">
            <h5 className="text-center text-uppercase">Informations professionnel</h5>
            <hr className='hr3' />
            <div className="">
              <p className="text-center mb-2 lh-sm fw-bold bg-orange-text">
                SIRET : <span className="text-white">923 357 909 00012</span>
              </p>
              <p className="text-center mb-2 lh-sm fw-bold bg-orange-text">
                <i className="fa fa-envelope"></i> :
                <a href="mailto:baptiste.prodev@gmail.com" className="text-decoration-none text-white"> baptiste.prodev@gmail.com</a>
              </p>
              <p className="text-center mb-2 lh-sm fw-bold bg-orange-text">
                <i className="fa fa-link"></i> :
                <a href="http://baptiste-wd.fr" target="_blank" rel="noreferrer" className="text-decoration-none text-white"> baptiste-wd.fr</a>
              </p>
            </div>
          </div>

          <div className="col d-flex flex-column justify-content-center mb-5">
            <h5 className="text-center text-uppercase">Informations</h5>
            <hr className='hr3' />
            <div className="">
              <p className="text-center mb-2 lh-sm fw-bold bg-orange-text">
                <i className="fa fa-phone"></i> :
                <a href="tel:0623647928" className="text-decoration-none text-white"> 07 66 27 33 78
                </a>
              </p>
              <p className="text-center mb-2 lh-sm fw-bold bg-orange-text">
                <i className="fa fa-envelope"></i> :
                <a href="mailto:bapt.bouteille@gmail.com" className="text-decoration-none text-white"> bapt.bouteille@gmail.com</a>
              </p>
              <p className="text-center mb-2 lh-sm fw-bold bg-orange-text">
                <i className="fa fa-link"></i> :
                <a href="http://baptistebouteille.fr" target="_blank" rel="noreferrer" className="text-decoration-none text-white"> baptistebouteille.fr</a>
              </p>
            </div>
          </div>

        </footer>
      </div>
    </>
  );
};

export default MyPage;