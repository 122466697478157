const Experiences = (props) => {

    const img = props.img;
    const title = props.title;
    const times = props.times;
    const style = props.style;
    
    function li() {
        
        const liste = props.liste

        return liste.map(element => {
            
            return (
                <li><i className="fa-solid fa-chevron-right color-dark"></i> {element}</li>
            );

        });

    };

    return (
        <>
            <div className="col-12 col-md-4">
                <div animation="animate__jackInTheBox" className="d-flex flex-column h-100 animate__animated">
                    <div className="mt-auto mb-auto">
                        <div className="text-center">
                            <img src={img} className={"h-100 p-4 "+style+""} alt={img} />
                            <h3 className="mt-3 mb-0 text-uppercase">{title}</h3>
                            <span className="text-muted text-uppercase">{times}</span>
                        </div>
                        <div className="mt-3 text-start mb-0">
                            <ul className="p-0">
                                {li()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Experiences;