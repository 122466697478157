import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import React, { useState, useEffect } from 'react';
import './style.css';
import MyPage from './pages/MyPages';
import Loader from './Loader';
import Notification from './notification';


const App = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2500);
  }, []);

  const [notifications, setNotifications] = useState([]);
  const sendNotification = (title, content, timeout, style) => {
    const notification = (
      <Notification title={title} badge="développeur" timeout={timeout} style={style}>
        {content}
      </Notification>
    )
    setNotifications(notifications => [...notifications, notification])
  }

  return loader ? (
    <Loader />
  ) : (
    <>
      <div className="toast-container position-fixed bottom-0 start-0 p-3">
        {
          notifications.map(notification => {
            return notification
          })
        }
      </div>
      <MyPage sendNotification={sendNotification}/>
    </>
  );
};

export default App;