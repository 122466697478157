import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const Article = (props) => {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const { img, title, type, description, icons, lient, model } = props;

    function formats(element) {
        var format;
        if (element === 'database') {
            format = 'solid';
        } else {
            format = 'brands';
        }
        return format;
    }

    function nbicon() {
        return icons.map((element) => {
            return (
                <div className="col-auto ps-0 d-flex">
                    <i className={`fs-4 mt-auto mb-auto fa-${formats(element)} fa-${element} dark-color`}></i>
                </div>
            );
        });
    }

    return (
        <>
            <div className="animate__animated card h-100 shadow border rounded d-flex flex-column bg-dark">
                <img src={img} alt={img} className="card-img-top h-perso" style={model} />
                <div className="card-body p-3 d-flex flex-column">
                    <h3 style={{ textAlign: 'center' }} className="card-title fw-bolder lh-1">
                        {title}
                    </h3>

                    <div className="mt-auto ms-1 row justify-content-start dark-color">
                        <>
                            {nbicon()}
                        </>
                        <div className="col d-flex">
                            <div className="ms-auto">
                                <span className="badge bg-prymari">{type}</span>
                            </div>
                        </div>
                    </div>
                    <button className="learn-more mt-2 btn btn-outline-light" onClick={handleShowModal}>
                        <span className="text-uppercase fw-bold">Voir plus</span>
                    </button>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header className="bg-dark text-white d-flex justify-content-between">
                    <Modal.Title className="text-uppercase fw-bold">{title}</Modal.Title>
                    <div className="row justify-content-end">
                        <>
                            {nbicon()}
                        </>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <p className="text-dark lh-sm">{description}</p>
                                    <img src={img} alt={img} className="border-bottom w-100 h-100 mt-5" style={model} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between bg-dark text-white">
                    <a target="_blank" href={lient} className="text-decoration-none">
                        <button className="btn btn-outline-light">
                            Accéder au site
                        </button>
                    </a>
                    <div>
                        <button type="button" className="btn btn-outline-danger" onClick={handleCloseModal}>
                            Fermer <i className="fa-solid fa-rotate-left"></i>
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Article;
